import React from 'react';
import { graphql } from 'gatsby';
import { Box, Flex } from '../style/basicStyle';
import LayoutWithAuth from '../components/layoutWithAuth';
import SignUpForm from '../components/SignUp';

// eslint-disable-next-line react/prop-types
const Named = ({ location }) => {
  // eslint-disable-next-line react/prop-types
  const redirect = location && location.state && location.state.redirect;
  return (
    <Box py={[5]}>
      <LayoutWithAuth>
        <SignUpForm redirect={redirect} />
      </LayoutWithAuth>
    </Box>
  );
};

export default Named;

export const pageQuery = graphql`
  query signupContentQuery {
    pageData: allPageDataJson(filter: { name: { eq: "signup" } }) {
      edges {
        node {
          name
          title
          subtitle
          subtitle2
        }
      }
    }
  }
`;
